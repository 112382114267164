<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.client_media_code" @change="Search" placeholder="社媒标识"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddBrandMedia">增加社媒</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="品牌标识">
        <template v-slot="scope">
          <span>{{ scope.row.client_brand_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="社媒标识">
        <template v-slot="scope">
          <span>{{ scope.row.client_media_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图标s3url">
        <template v-slot="scope">
          <span>{{ scope.row.icon }}</span>
        </template>
      </el-table-column>
      <el-table-column label="社媒名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="社媒地址">
        <template v-slot="scope">
          <span>{{ scope.row.url }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="EditBrandMedia(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="DeleteBrandmedia(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title + '社媒'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <!-- <el-form-item label="品牌标识" prop="client_brand_code" label-width="100px">
          <el-input v-model="form.client_brand_code" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="社媒标识" prop="client_media_code" label-width="100px">
          <el-input v-model="form.client_media_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon" label-width="100px">
          <el-upload
            class="avatar-uploader"
            :action="previews3url"
            :show-file-list="false"
            :http-request="fileUpload"
            @change="handleChange"
            :auto-upload="false"
            >
            <img v-if="previewUrl" :src="previewUrl" class="avatar avatarmedia" style="width:50px;height:50px">
            <i v-else class="el-icon-plus avatar-uploader-icon avatar-uploader-iconmedia" style="width: 50px;height: 50px;line-height: 50px;"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="社媒名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="社媒地址" prop="url" label-width="100px">
          <el-input v-model="form.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安卓包名" label-width="100px">
          <el-input v-model="form.android.package_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安卓跳转目标" label-width="100px">
          <el-input v-model="form.android.action" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安卓请求协议" label-width="100px">
          <el-input v-model="form.android.protocol" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="IOS包名" label-width="100px">
          <el-input v-model="form.ios.package_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="IOS跳转目标" label-width="100px">
          <el-input v-model="form.ios.action" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="IOS请求协议" label-width="100px">
          <el-input v-model="form.ios.protocol" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">{{edit?'修改':'新增'}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
        search: {
            page: 1,
            limit: 10,
            client_brand_code: '',
            kname: '',
            client_media_code: '',
        },
        list: [],
        total: 0,
        dialogFormVisible: false,
        title: '',
        edit: false,
        form: {
            client_brand_code: '',
            client_media_code: '',
            icon: '',
            name: '',
            url: '',
            android: {
              package_name: '',
              action: '',
              protocol: '',
            },
            ios: {
                package_name: '',
                action: '',
                protocol: '',
            }
        },
        rules: {
            client_brand_code: [{ required: true, message: '请输入品牌标识', trigger: 'blur' }],
            client_media_code: [{ required: true, message: '请输入社媒标识', trigger: 'blur' }],
            icon: [{ required: true, message: '请输入图标s3url', trigger: 'blur' }],
            name: [{ required: true, message: '请输入社媒名称', trigger: 'blur' }],
            url: [{ required: true, message: '请输入社媒地址', trigger: 'blur' }],
        },
        previews3url: '',
        previewUrl: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 文件上传
    handleChange (event) {
      this.GetMd5(event.raw)
    },
    GetMd5(file) {
      util.getFileMD5(file, md5 => {
        let params = {
          key_action: 'icon',
          key_md5: md5,
          key_type: 'display',
          key_suffix: file.name.split('.')[1],
          content_length: file.size,
          content_type: file.type?file.type:file.name.split('.')[1]
        }
        this.GetPreSign(params,file)
      })
    },
    GetPreSign (params,file) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          this.previewUrl = res.data.data.get_url
          this.form.icon = res.data.data.get_url
          this.previews3url = res.data.data.put_url
          this.fileUpload(file,res.data.data.put_url) 
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    fileUpload (file,urls) {
      let config = {
        headers: {
          'Content-Type': file.type?file.type:'html',
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        }
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      let url = '/aws' + urls.split(urlReg.exec(urls)[0])[1]
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
        }
      }).catch( err => {
        console.log(err)
        this.$message.error('上传失败:'+err)
      })
    },
    handleCurrentChange() {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.BrandMediaList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    AddBrandMedia() {
        this.title = '新增'
        this.edit = false
        this.previewUrl = '';
         console.log(this.form);
        this.dialogFormVisible = true
    },
    EditBrandMedia(item) {
        this.title = '编辑'
        this.edit = true
        this.api.BrandMediaDetail({
            client_brand_code: item.client_brand_code,
            client_media_code: item.client_media_code,
        }).then(res => {
            if (res.data.code == 200) {
                this.form = res.data.data
                this.previewUrl = res.data.data.icon
                this.form.android = JSON.parse(res.data.data.android)
                this.form.ios = JSON.parse(res.data.data.ios)
                this.dialogFormVisible = true
                return
            }
            this.$message.error('获取详情失败' + res.data.msg)
        })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Addconfirm() {
        this.api.BrandMediaUpdate(this.form).then(res => {
            if (res.data.code == 200) {
                this.$message.success(this.title + '成功')
                this.Search()
                this.dialogFormVisible = false
            return
            }
            this.$message.error(this.title + '失败' + res.data.msg)
        })
    },
    DeleteBrandmedia(item) {
      this.$confirm('是否删除该社媒?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.BrandMediaDelete({
          client_brand_code: item.client_brand_code,
          client_media_code: item.client_media_code,
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({type: 'success',message: '删除成功!'});
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback (){
      this.$router.push('/iotmanage/brand')
    }
  },
  filters: {},
  mounted () {
    this.search.client_brand_code = this.$route.params.code
    this.form.client_brand_code = this.$route.params.code
    this.Search()
  },
  created () { }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-iconmedia {
    font-size: 28px;
    color: #8c939d;
    text-align: center;
  }
  .avatarmedia {
    display: block;
  }
</style>
